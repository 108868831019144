import { Box, Button, Text, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion"
import React from "react"
import { chakra } from "@chakra-ui/react"
const ChakraBox1 = chakra(motion.div, {
  shouldForwardProp: isValidMotionProp,
})
export const Modal2 = ({ isToggled, setToggle, children }) => {
  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          position="relative"
          top={{ base: "0px", lg: "250px" }}
          left={{ base: 0, lg: "25%" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          mt={{ base: 0, lg: "25vh" }}
        >
          <Box
            w={{ base: "90vw", md: "50vw" }}
            mt={{ base: "2vh", lg: "25vh" }}
            as={motion.div}
            position="fixed"
            zIndex="99999"
            initial={{ y: 50, scale: 0.2 }}
            animate={{ y: 0, scale: 1 }}
            exit={{ y: -50, scale: 0.1 }}
            p={{ base: 4, lg: 8 }}
            top={"0"}
            left={{ base: "4%", lg: "25%" }}
            boxShadow="xl"
            bg={"#ffffff"}
            borderRadius="11px"
          >
            <Heading
              fontSize={{ base: "3xl", lg: "5xl" }}
              fontWeight="normal"
              color="brand.violet.light"
              mb="4"
              w={"100%"}
              letterSpacing="tighter"
            >
              DVT - Digitale Volumen-Tomografie
            </Heading>
            <Text
              color="brand.gray.light"
              fontSize={{ base: "md", md: "lg" }}
              fontWeight="normal"
            >
              Eine hochauflösende Feinschicht computertomografische Aufnahme mit
              neuer Technologie und damit bis zu 10x geringere Strahlenbelastung
              als klassisches CT, sowie oft detailreichere Aufnahmen ohne
              Artefakte, insbesondere gestochen scharfe Abbildungen (100-150
              Mikrometern) von Knochenbälkchen, Wurzelkanälen, Nervenkanälen,
              natürlich auch Zysten.
            </Text>
            <Text
              color="brand.gray.light"
              mt={12}
              fontSize={{ base: "md", md: "lg" }}
              fontWeight="normal"
            >
              Damit ist dieses unsere erste Wahl bei Bildgebenden Verfahren, da
              es uns ermöglicht eine virtuelle Planung der gewünschten
              Operationen durchzuführen, das bezieht sich auf operative
              Zugangswege, gefährdete Nachbarstrukturen,
              Implantatlängen/breiten, Nervenverläufe bei verlagerten Zähnen
              etc.
            </Text>

            <Button
              mt={12}
              px={6}
              textAlign="center"
              variant={"ghost"}
              color="brand.violet.light"
              onClick={() => setToggle(false)}
            >
              Schliessen
            </Button>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
